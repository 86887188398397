
.AboutPage {
    font-family: 'Nunito', sans-serif;
    color: rgb(222, 227, 221);
    background-color: rgb(37, 37, 37);
    min-height: 100vh;
    position: relative;
    z-index: 0;
    padding-bottom: 2em;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.page-title {
    margin: 0;
    padding-top: 2.5em;
}

.conditional-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logos-component {
    padding-bottom: 2em;
}

@media only screen and (max-width: 1370px) {
    
    .AboutPage {
        padding-bottom: 0;
    }

    #about-title {
        margin-bottom: -0.5em
    }

    .logos-title {
        margin-top: -0.5em;
        margin-bottom: -0.5em;
    }

}

@media only screen and (max-width: 1400px) and (min-resolution: 192dpi) {
    
    .AboutPage {
        padding-bottom: 0;
    }

    #about-title {
        margin-bottom: -0.5em
    }

    .logos-title {
        margin-top: -0.5em;
        margin-bottom: -0.5em;
    }

}

@media only screen and (max-width: 850px) {
    .logos-title {
        margin-bottom: -0.5em;
    }

    .AboutPage {
        padding-bottom: 0;
    }
}