

.logos-container {
    display: inline-grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    gap: 2rem;
    padding: 1em;
    justify-content: center;
    background-color: lightgray;
}

.logo-item {
    height: auto;
}

.logo-text {
    margin-top: 0.5em;
    color: rgb(37, 37, 37)
}

@media only screen and (max-width: 1370px) {
    .logos-container {
        transform: scale(.85);
    }
}

@media only screen and (max-width: 1400px) and (min-resolution: 192dpi) {
    .logos-container {
        transform: scale(.85);
    }
}

@media only screen and (max-width: 850px) {
    .logos-container {
        transform: scale(.85);
    }
}

@media only screen and (max-width: 590px) {
    .logos-container {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
}