
.Nav {
    font-family: 'Nunito', sans-serif;
    box-sizing: border-box;
}

.nav-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(37, 37, 37, 0.9);
    padding: 0.5em;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
}

.nav-links {
    display: flex;
    justify-content: center;
    padding: 0.5em;
}

.nav-link {
    padding: 0 6em 0 6em;
    font-size: 1.25em;
    color: rgb(222, 227, 221);
    text-decoration: none;
    transition: all 0.1s ease-in-out;
}

.nav-link:hover {
    text-decoration: underline;
    text-shadow: 2px 2px 4px rgba(222, 227, 221, 0.7);
}

a.active {
    font-weight: bold;
    color: rgb(155, 159, 154);
    font-size: 1.5em;
}

@media only screen and (max-width: 850px) {
    
    .nav-link:hover {
        text-shadow: none;
    }
    
    a.active {
        text-decoration: underline;
        font-size: 1.25em;
    }
}

@media only screen and (max-width: 768px) {

    .nav-links {
        margin: 0;
    }

    .nav-link {
        padding: 0 2em 0 2em;
        font-size: 1.25em;
    }

    .nav-link:hover {
        text-shadow: none;
    }

    a.active {
        text-decoration: underline;
        font-size: 1.25em;
    }
}

@media only screen and (max-width: 590px) {

    .nav-links{
        display: flex;
        justify-content: space-around;
    }
    
    .nav-link {
        font-size: 1em;
        padding: 0;
    }

    a.active {
        text-decoration: underline;
        font-size: 1em;
        padding: 0;
    }
}