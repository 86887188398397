
.ProjectsPage {
  font-family: 'Nunito', sans-serif;
  color: rgb(222, 227, 221);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(37, 37, 37);
  min-height: 100vh;
  position: relative;
  z-index: 0;
  padding-bottom: 2em;
}

.page-title {
  margin: 0;
}

.project-card-title {
  font-size: 1.75em;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 100px;
  perspective: 1000px; 
  margin-top: 10em;
}

@media (max-width: 1685px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1120px) {
  .project-grid {
    grid-template-columns: 1fr;
  }
}

.project-card {
  width: 30em; 
  height: 42em; 
  position: relative;
  transform-style: preserve-3d; 
  transition: transform 0.5s ease-in-out; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  transform: rotateX(0deg) rotateY(0deg);
}

.project-card.flipped {
  transform: rotateX(180deg)
}

.card-front, .card-back {
  position: relative;
  box-sizing: border-box;
}

.card-front:hover::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75)
}

.card-front:hover .details-button {
  display: block;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  cursor: pointer;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}


.card-front img {
  width: 75%;
  height: 75%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}

.card-front {
  cursor: pointer;
}

.card-back {
  background-color: #333;
  color: white;
  transform: rotateX(180deg); 
  display: flex;
  flex-direction: column;
}

.details-button {
  display: none;
  background: none;
  border: none;
  color: rgb(222, 227, 221);
  font-size: 1.75em;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: color 0.3s ease;

}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: rgb(222, 227, 221);
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.close-button:hover {
  transform: scale(1.25);
}

.tech-chips {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px 10px;
  margin-top: 2em;
}

.links-container {
  display: flex;
  flex-direction: column;
  margin-top: -1em;
}

.project-link {
  padding: 1em 0 1em 0
}

.github-url, .deploy-url {
  width: 12em;
  padding: 1em;
  cursor: pointer;
  color: rgb(222, 227, 221);
  background-color: rgb(37, 37, 37);
  font-size: 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.github-url:hover, .deploy-url:hover {
  color: rgb(37, 37, 37);
  background-color: rgb(222, 227, 221)
}

.project-description {
  padding: 1em;
}

.project-description .project-summary {
  font-size: 1.5em;
  font-weight: bold;
}

.project-description .project-bullets {
  font-size: 1.25em;
  padding-top: 0.25em;
}

.project-description .project-bullets li {
  padding: 0.5em;
}

@media only screen and (max-width: 1370px) {
  .project-grid {
    margin-top: -0.5em;
    gap: 1em 3em;
  }

  .project-card {
    transform: scale(.85)
  }
}

@media only screen and (max-width: 1400px) and (min-resolution: 192dpi) {
  .project-grid {
    margin-top: -0.5em;
    gap: 1em 3em;
  }

  .project-card {
    transform: scale(.85)
  }
}

@media only screen and (max-width: 850px) {

  .project-grid {
    margin: 3em;
  }

  .project-card {
    height: 51em;
  }

  .card-front:after {
    content: 'Tap for details';
    display: block;
    margin-top: 1.5em;
    font-style: italic;
  }

  .card-front:hover::before {
    background: transparent;
  }

  .links-container {
    margin-top: 2em;
  }

  .project-link {
    padding: 1.5em 0 1.5em 0;
  }

  .github-url, .deploy-url {
    font-size: 1.5em;
  }

  .github-url:hover, .deploy-url:hover {
    color: rgb(222, 227, 221);
    background-color: rgb(37, 37, 37);
  }
}

@media only screen and (max-width: 590px) {

  .ProjectsPage {
    padding-bottom: 0;
    margin-bottom: -2em;
  }

  .project-card {
    width: 23em;
    height: 40em;
  }

  .card-front img {
    width: 75%;
    height: 60%;
  }

  .project-grid {
    gap: 50px;
  }

  .project-description {
    padding: 0 .25em 0 .25em;
  }
  
  .project-description .project-summary {
    font-size: 1.25em;
    font-weight: bold;
  }
  
  .project-description .project-bullets {
    font-size: 1.125em;
    font-weight: bold;
    padding-top: 0.125em;
  }
  
  .project-description .project-bullets li {
    padding: 0.25em;
  }

  .links-container {
    margin-top: 0;
  }

  .project-link {
    padding: 1.25em 0 1.25em 0;
  }

  .github-url, .deploy-url {
    font-size: 1em;
  }

  .github-url, .deploy-url {
    width: 10em;
    padding: 1em;
  }
}