
.AboutMe {
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: center;
}

.about-me-text {
    font-size: 1.5em;
    width: 50vw;
}

@media only screen and (max-width: 1370px) {
    .about-me-text {
        font-size: 1.25em;
        width: 85vw;
    }
}

@media only screen and (max-width: 1400px) and (min-resolution: 192dpi) {
    .about-me-text {
        font-size: 1.25em;
        width: 85vw;
    }
}

@media only screen and (max-width: 850px) {
    .about-me-text {
        font-size: 1.25em;
        width: 80vw;
    }
}