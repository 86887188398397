
.IntroAnimation {
    height: 75vh;
    width: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(37, 37, 37);
}

.animation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    height: 50vh;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.console-underscore {
    font-size: 8em;
    animation: blink 1.25s infinite;
    color: rgb(238, 238, 238);
}

.typed-text {
    font-family: 'VT323', monospace;
    font-size: 8em;
    color: rgb(238, 238, 238);
}

.skip-btn {
    opacity: 0;
    display: inline-block;
    position: absolute;
    bottom: 10em;
    padding: 20px 40px;
    background-color: rgb(37, 37, 37);
    color: rgb(238, 238, 238);
    border: solid;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s, opacity 1s ease-in-out;
    transition-delay: 1s;
    font-size: 1.25em;
}

.skip-btn.show {
    opacity: 1;
}

.skip-btn:active {
    box-shadow: 3px 3px 6px #B5BDC8, -3px -3px 6px #FFFFFF;
}

@media only screen and (max-width: 1370px) {

    .console-underscore {
        font-size: 4em;
    }

    .typed-text {
        font-size: 4em;
    }
}

@media only screen and (max-width: 1400px) and (min-resolution: 192dpi) {

    .console-underscore {
        font-size: 4em;
    }

    .typed-text {
        font-size: 4em;
    }
}

@media only screen and (max-width: 850px) {

    .console-underscore {
        font-size: 3em;
    }

    .typed-text {
        font-size: 3em;
    }
}