
html {
    scroll-behavior: smooth;
}
.HomePage {
    font-family: 'Nunito', sans-serif;
    overflow: hidden;
}

.wrapper {
    background-color: rgb(37, 37, 37);
    height: 100vh;
    position: relative;
    z-index: 0;
}

.parallax-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
}

.parallax-background {
    background-image: url('../../images//code-image.jpg');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.parallax-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    color: rgb(222, 227, 221);
    background-color: rgba(37, 37, 37, 0.95);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2em;
}

.parallax-content:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
    transition: all 0.3s ease;
}

.parallax-header {
    font-size: 4em;
}

.parallax-body {
    font-size: 3em;
}

.profile-pic {
    border-radius: 50%;
    width: 300px;
    height: 300px;
}

.social-links {
    margin-top: 1.5em;
    display: flex;
    justify-content: space-between;
    width: 200px;
}

.linkedin-logo, .resume-logo {
    height: 75px;
    width: 75px;
    margin-bottom: -1em;
}

.linkedin-link, .resume-link {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
}

@media only screen and (max-width: 1370px) {

    .parallax-content {
        margin-top: 3em;
    }

    .parallax-header {
        font-size: 3em;
    }

    .parallax-body {
        font-size: 2em;
        margin-top: -0.25em;
    }

    .profile-pic {
        width: 200px;
        height: 200px;
    }

    .social-links {
        width: 180px;
    }

    .linkedin-logo, .resume-logo {
        height: 60px;
        width: 60px;
    }

    .social-text {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 1400px) and (min-resolution: 192dpi) {
    
    .parallax-content {
        margin-top: 3em;
    }
    
    .parallax-header {
        font-size: 3em;
    }
    
    .parallax-body {
        font-size: 2em;
        margin-top: -0.25em;
    }
    
    .profile-pic {
        width: 200px;
        height: 200px;
    }
    
    .social-links {
        width: 180px;
    }
    
    .linkedin-logo, .resume-logo {
        height: 60px;
        width: 60px;
    }
    
    .social-text {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 590px) {
    .parallax-header {
        font-size: 2em;
    }

    .parallax-body {
        font-size: 1.5em;
    }

    .profile-pic {
        width: 10em;
        height: 10em;
    }

    .linkedin-logo, .resume-logo {
        width: 3em;
        height: 3em;
        margin-bottom: -1em;
    }

    .social-links {
        display: flex;
        justify-content: space-around;
    }

    .social-text {
        font-size: 0.9em;
    }
}